import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle, Form } from "reactstrap";

//import Components
import SearchOption from "../Components/Common/SearchOption";
// import LanguageDropdown from "../Components/Common/LanguageDropdown";
import FullScreenDropdown from "../Components/Common/FullScreenDropdown";
import NotificationDropdown from "../Components/Common/NotificationDropdown";
import ProfileDropdown from "../Components/Common/ProfileDropdown";
import LightDark from "../Components/Common/LightDark";
import {
  changeLayout,
  //   changeSidebarTheme,
  changeLayoutMode,
  //   changeLayoutWidth,
  //   changeLayoutPosition,
  //   changeTopbarTheme,
  //   changeLeftsidebarSizeType,
  //   changeLeftsidebarViewType,
  //   changeSidebarImageType,
} from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Settings from "../Components/Commoncomponents/Common/Settings";

const Header = ({
  setShowhotel,
  dashboard,
  pagename,
  user_type,
  showhotel,
}) => {
  let navigate = useNavigate();

  const [search, setSearch] = useState(false);
  const [headerClass, setHeaderClass] = useState("");

  const dispatch = useDispatch();
  const {
    // layoutType,
    // leftSidebarType,
    layoutModeType,
    // layoutWidthType,
    // layoutPositionType,
    // topbarThemeType,
    // leftsidbarSizeType,
    // leftSidebarViewType,
    // leftSidebarImageType,
  } = useSelector((state) => ({
    // layoutType: state.Layout.layoutType,
    // leftSidebarType: state.Layout.leftSidebarType,
    layoutModeType: state.Layout.layoutModeType,
    // layoutWidthType: state.Layout.layoutWidthType,
    // layoutPositionType: state.Layout.layoutPositionType,
    // topbarThemeType: state.Layout.topbarThemeType,
    // leftsidbarSizeType: state.Layout.leftsidbarSizeType,
    // leftSidebarViewType: state.Layout.leftSidebarViewType,
    // leftSidebarImageType: state.Layout.leftSidebarImageType,
  }));
  useEffect(() => {
    if (
      //   layoutType ||
      //   leftSidebarType ||
      layoutModeType
      //   layoutWidthType ||
      //   layoutPositionType ||
      //   topbarThemeType ||
      //   leftsidbarSizeType ||
      //   leftSidebarViewType ||
      //   leftSidebarImageType
    ) {
      //   dispatch(changeLeftsidebarViewType(leftSidebarViewType));
      //   dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
      //   dispatch(changeSidebarTheme(leftSidebarType));
      dispatch(changeLayoutMode(layoutModeType));
      //   dispatch(changeLayoutWidth(layoutWidthType));
      //   dispatch(changeLayoutPosition(layoutPositionType));
      //   dispatch(changeTopbarTheme(topbarThemeType));
      //   dispatch(changeLayout(layoutType));
      //   dispatch(changeSidebarImageType(leftSidebarImageType));
    }
  }, [
    // layoutType,
    // leftSidebarType,
    layoutModeType,
    // layoutWidthType,
    // layoutPositionType,
    // topbarThemeType,
    // leftsidbarSizeType,
    // leftSidebarViewType,
    // leftSidebarImageType,
    // dispatch,
  ]);
  /*
  call dark/light mode
  */
  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value));
    }
  };

  // class add remove in header
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow");
    } else {
      setHeaderClass("");
    }
  }
  const toogleSearch = () => {
    setSearch(!search);
  };

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;

    if (windowSize > 767)
      document.querySelector(".hamburger-icon").classList.toggle("open");

    //For collapse horizontal menu
    if (document.documentElement.getAttribute("data-layout") === "horizontal") {
      document.body.classList.contains("menu")
        ? document.body.classList.remove("menu")
        : document.body.classList.add("menu");
    }

    //For collapse vertical menu
    if (document.documentElement.getAttribute("data-layout") === "vertical") {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
  };

  function handleclear() {
    localStorage.removeItem("selected-hotel");
    localStorage.removeItem("selected-hotel-id");
    setShowhotel(false);
    navigate("/dashboard");
  }
  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              {pagename === "basic" ||
              pagename === "room" ||
              pagename === "condition" ||
              pagename === "loyalty" ||
              pagename === "privatesale" ||
              pagename === "directrateloyalty" ? (
                <Link to="/hotel-dashboard" className="backlink">
                  <i class="ri-arrow-left-line"></i>
                </Link>
              ) : (
                ""
              )}
              <div className="navbar-brand-box horizontal-logo">
                {showhotel === true ? (
                  <Link to="#" onClick={handleclear} className="logo logo-dark">
                    <span className="logo-sm">
                      <img src="/images/DR_white.png" alt="" height="35" />
                      {/* <img
                        fetchpriority="high"
                        src="https://thedirectrate.com/wp-content/uploads/2024/05/logo-white-600.png#1564"
                        alt="logo-white-600"
                        height="22"
                      /> */}
                    </span>
                    <span className="logo-lg">
                      {/* <img src="/images/logo-dark.png" alt="" height="40" /> */}
                      <img src="/images/DR_white.png" alt="" height="35" />
                    </span>
                  </Link>
                ) : (
                  <Link to="/dashboard" className="logo logo-dark">
                    <span className="logo-sm">
                      {/* <img src="/images/logo-dark.png" alt="" height="22" /> */}
                      <img src="/images/DR_white.png" alt="" height="35" />
                    </span>
                    <span className="logo-lg">
                      {/* <img src="/images/logo-dark.png" alt="" height="40" /> */}
                      <img src="/images/DR_white.png" alt="" height="35" />
                    </span>
                  </Link>
                )}
                {showhotel === true ? (
                  <Link
                    to="#"
                    onClick={handleclear}
                    className="logo logo-light"
                  >
                    <span className="logo-sm">
                      {/* <img src="/images/logo-dark.png" alt="" height="22" /> */}
                      <img src="/images/DR_white.png" alt="" height="35" />
                    </span>
                    <span className="logo-lg">
                      {/* <img src="/images/logo-dark.png" alt="" height="40" /> */}
                      <img src="/images/DR_white.png" alt="" height="35" />
                    </span>
                  </Link>
                ) : (
                  <Link to="/dashboard" className="logo logo-light">
                    <span className="logo-sm">
                      {/* <img src="/images/logo-dark.png" alt="" height="22" /> */}
                      <img src="/images/DR_white.png" alt="" height="35" />
                    </span>
                    <span className="logo-lg">
                      {/* <img src="/images/logo-dark.png" alt="" height="40" /> */}
                      <img src="/images/DR_white.png" alt="" height="35" />
                    </span>
                  </Link>
                )}
              </div>

              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>

              {user_type === "hotel-user" ? (
                ""
              ) : (
                <SearchOption
                  setShowhotel={setShowhotel}
                  showhotel={showhotel}
                />
              )}
            </div>

            <div className="d-flex align-items-center">
              <Dropdown
                isOpen={search}
                toggle={toogleSearch}
                className="d-md-none topbar-head-dropdown header-item"
              >
                <DropdownToggle
                  type="button"
                  tag="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                >
                  <i className="bx bx-search fs-22"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                  <Form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Choose Hotel..."
                          aria-label="Recipient's username"
                        />
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </Form>
                </DropdownMenu>
              </Dropdown>

              {/* LanguageDropdown */}
              {/* <LanguageDropdown /> */}

              {/* WebAppsDropdown */}
              {/* <WebAppsDropdown /> */}

              {/* MyCartDropdwon */}
              {/* <MyCartDropdown /> */}

              {/* FullScreenDropdown */}
              <FullScreenDropdown />

              {/* Dark/Light Mode set */}
              <LightDark
                layoutMode={layoutModeType}
                onChangeLayoutMode={onChangeLayoutMode}
              />

              {/* NotificationDropdown */}
              {/* <NotificationDropdown /> */}
              {/* <Settings/> */}
              {/* ProfileDropdown */}
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
